import { jwtDecrypt, tokenAlive } from "../../shared/jwtHelper";
import jwtInterceptor from '../../shared/jwt.interceptor'
import axios from "axios"
import config from '../../shared/config'

const state = () => ({
  authData: JSON.parse(localStorage.getItem('authDataStorage')) || {},
  // authData: {
  //   token: localStorage.getItem('access_token') || '',
  //   tokenExp: localStorage.getItem('tokenExp') || '',
  //   userId: '',
  //   userName: '',
  //   role: ''
  // },
  loggedIn: false,
});

const getters = {
  getLoginStatus(state) {
    return state.loggedIn;
  },
  getAuthData(state) {
    return state.authData;
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false;
    }
    return tokenAlive(state.authData.tokenExp);
  },
};

const actions = {
  async useRefreshToken({ commit }) {
    const response = await axios
    .post(config.backend_server + "accounts/refresh-token", {},
      { withCredentials: true }
    ).catch((error) => {
      console.log(error);
      return error.response
    });
    if (response && response.data && response.status === 200) {
      commit("saveTokenData", response.data);
      commit("setLoginStatus", true);
      console.log('authStore: 200 from useRefreshToken')
    } else {
      commit("setLoginStatus", false);
      console.log('authStore: not 200 from useRefreshToken')
    }
    return response
  },
  async register(context, payload) {
    const response = await axios
    .post(config.backend_server + "accounts/register", payload)
    .catch((error) => {
      console.log(error);
      return error.response
    });
    return response
  },
  async login({ commit }, payload) {
    const response = await axios
      .post(
        config.backend_server + "accounts/authenticate",
        payload,
        // setting this allows to save the value of the refresh token
        // to the clinet cookies. The server response contains header
        // set-cookie with the refresh token. This also attaches current
        // client cookies to the requests but we do not need that.
        { withCredentials: true }
      )
      .catch((error) => {
        console.log(error);
        return error.response
      });
    if (response && response.data && response.status === 200) {
      commit("saveTokenData", response.data);
      commit("setLoginStatus", true);
      console.log('authStore: 200 from login')
    } else {
      commit("setLoginStatus", false);
      console.log('authStore: not 200 from login')
    }
    return response
  },
  async resetPassword(context, payload) {
    const response = await axios
    .post(config.backend_server + "accounts/reset-password", payload)
    .catch((error) => {
      console.log(error);
      return error.response
    });
    return response
  },
  async forgotPassword(context, payload) {
    const response = await axios
    .post(config.backend_server + "accounts/forgot-password", payload)
    .catch((error) => {
      console.log(error);
      return error.response
    });
    return response
  },
  async verifyEmail(context, payload) {
    const response = await axios
    .post(config.backend_server + "accounts/verify-email", payload)
    .catch((error) => {
      console.log(error);
      return error.response
    });
    return response
  },
  async getUsers() {
    const response = await jwtInterceptor
      .get(config.backend_server + "accounts")
      .catch((error) => {
        console.log(error)
        return error.response
      })
    console.log(response)
    return response
  },
  // we can not call saveTokenData directly.
  // Remember that mutations have to be synchronous.
  // that is why across whole vuex, we call actions
  // to commit mutations. No other way.
  async saveTokenData({commit}, data) {
    commit('saveTokenData', data)
  },
  logout({commit}) {
    commit("logout")
  }
};

const mutations = {
  saveTokenData(state, data) {
    console.log('saving login data to localstorage etc')
    // localStorage.setItem("access_token", data.jwtToken)
    localStorage.setItem('authDataStorage', JSON.stringify(data))

    // jwt payload contains expiration date
    // lets decode it and save the value for later use
    // although at this point, I don't think we need to use it
    // becase jwtinterceptor takes care of expired tokens.
    const jwtDecodedValue = jwtDecrypt(data.jwtToken);
    console.log('decrypted jwtToken', jwtDecodedValue)
    // localStorage.setItem('tokenExp', jwtDecodedValue.exp);
    const newTokenData = {
      token: data.jwtToken,
      tokenExp: jwtDecodedValue.exp,
      userId: data.id,
      userName: data.firstName,
      userLastName: data.lastName,
      role: data.role,
      lastLogin: data.lastLogin,
      verified: data.verified,
      passwordReset: data.passwordReset,
      email: data.email,
    };

    localStorage.setItem('authDataStorage', JSON.stringify(newTokenData))
    state.authData = newTokenData;
  },
  setLoginStatus(state, value) {
    state.loggedIn = value;
  },
  logout(state) {
    console.log('logging out')
    for (var key of Object.keys(state.authData)) {
      state.authData[key] = ''
    }
    state.loggedIn = false
    localStorage.removeItem('authDataStorage')
    localStorage.removeItem('userData')
    document.cookie = 'refreshToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
