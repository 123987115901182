const config = {
  // localhost
  //backend_server: 'http://localhost:4000/'

  // fontend proxy
  backend_server: 'https://vedlys.vpt.lt/api/'

  // backend direct
  // backend_server: 'http://34.134.51.152:4000/'
}

export default config;
