import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

const state = () => ({
  lessonData: {},
  updatedLessons: [],
});

const getters = {
  getLessonData(state) {
    return state.lessonData
  },
  // getUpdatedLessons(state) {
  //   return state.updatedLessons
  // }
}

const actions = {
  async getLesson({ commit }, id) {
    const response = await jwtInterceptor
    .get(config.backend_server + "lessons/" + id, {}
    ).catch((error) => {
      console.log(error)
      return error.response
    })
    if (response && response.data && response.status === 200) {
      console.log('lessonStore: 200 from getLesson')
      commit("saveLesson", response.data)
    } else {
      console.log('lessonStore: Not 200 from getLesson')
    }
    return response
  },
  async putLesson({ commit }, payload) {
    const response = await jwtInterceptor
    .put(config.backend_server + "lessons/" + payload.doc._id, payload
    ).catch((error) => {
      console.log(error)
      return error.response
    })
    if (response && response.data && response.status === 200) {
      console.log('lessonStore: 200 from putLesson')
      commit("saveLesson", response.data)
    } else {
      console.log('lessonStore: Not 200 from putLesson')
    }
    return response
  },
  // async getUpdatedLessons({ commit }, userId) {
  //   // console.log(userId)
  //   const response = await jwtInterceptor
  //   .get(config.backend_server + "lessons/updatedLessons/" + userId, {}
  //   ).catch((error) => {
  //     console.log(error)
  //     return error.response
  //   })
  //   if (response && response.data && response.status === 200) {
  //     console.log('lessonStore: 200 from getUpdatedLessons')
  //     commit("saveUpdatedLessons", response.data)
  //   } else {
  //     console.log('lessonStore: Not 200 from getUpdatedLessons')
  //   }
  //   return response
  // },

};

const mutations = {
  saveLesson(state, value) {
    state.lessonData[value._id] = value
  },
  saveUpdatedLessons(state, value) {
    state.updatedLessons = value
  },
  removeUpdatedLesson(state, value) {
    console.log('from store', state, value)
    const index = state.updatedLessons.indexOf(value)
    if (index > -1) {
      state.updatedLessons.splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
