import axios from "axios";
import store from '../store/index';
import config from '../shared/config'

const jwtInterceptor = axios.create({});


jwtInterceptor.interceptors.request.use((config) => {
    const authData = store.getters["auth/getAuthData"];
    if (authData == null) {
      return config;
    }

    config.headers.common["Authorization"] = `bearer ${authData.token}`;
    return config;
  }
);

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      // the following attaches client cookies to the requests
      // this includes refreshToken value
      var response = await axios.post(
        config.backend_server + "accounts/refresh-token", {},
        { withCredentials: true }
      );
      await store.dispatch("auth/saveTokenData", response.data);
      error.config.headers["Authorization"] = `bearer ${response.data.jwtToken}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;
