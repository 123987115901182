<template>
  <n-config-provider :theme="this.store.getters['theme/getTheme']" :theme-overrides="this.store.getters['theme/getThemeOverrides']">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-global-style />
          <router-view :class="store.getters['theme/removeColors'] ? 'noColor' : ''"/>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script>

import { NConfigProvider, NGlobalStyle, NMessageProvider, NNotificationProvider, NLoadingBarProvider } from 'naive-ui'
import { useStore } from 'vuex'
import '@/assets/vfonts/FiraCode.css'

export default {
  name: 'App',
  components: {
    NConfigProvider,
    NMessageProvider,
    NNotificationProvider,
    NGlobalStyle,
    NLoadingBarProvider,
  },
  setup() {
    return {
      store: useStore(),
    }
  }
}
</script>

<style>
  @import './assets/css/href_style.css';
  @import './assets/css/edits.css';

  #app {
  }

  /* force black and white */
  .noColor {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }

</style>
