// import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js'

export const loadingBarApiRef = {}

export default function createDemoRouter (app, routes) {
 const router = createRouter({
   scrollBehavior() {
     return {
       top: 0,
     }
   },
   history: createWebHistory(),
   routes
 })

 router.beforeEach(function (to, from, next) {

   // loading animation
   if (!from || to.path !== from.path) {
     if (loadingBarApiRef.value) {
       loadingBarApiRef.value.start()
     }
   }

   // auth check
   if(to.matched.some(record => record.meta.requiresAuth)) {
     // new auth check
     console.log('AUTH CHECK: inside router')
     const exp = store.getters['auth/getAuthData'].tokenExp
     if (!exp || Date.now() >= exp * 1000) {
       console.log('AUTH CHECK: redirecting to root')
       next('/auth')
     } else {
       console.log('AUTH CHECK: valid tokenExp')
     }
     //
     // console.log('AUTH CHECK', store.getters['auth/getLoginStatus'])
     // if (store.getters['auth/getLoginStatus']) {
     //   next()
     //   return
     // } else {
     //   console.log('REDIRECTING TO ROOT')
     //   next('/auth')
     // }
   }

   // finally
   next()
 })

 router.afterEach(function (to, from) {
   if (!from || to.path !== from.path) {
     if (loadingBarApiRef.value) {
       loadingBarApiRef.value.finish()
    }
   }
 })

 return router
}
