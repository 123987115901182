import { createApp } from 'vue'
import App from './App.vue'
// import naive from 'naive-ui'
import store from './store/index'

// naive-ui
// https://www.naiveui.com/en-US/dark/docs/import-on-demand
import {
  create,

  NConfigProvider,
  NInput,
  NButton,
  NButtonGroup,
  NH1,
  NH2,
  NH3,
  NH4,
  NLoadingBarProvider,
  NSpace,
  NCard,
  NTabPane,
  NTabs,
  NSpin,
  NLayout,
  NFormItem,
  NForm,
  NModal,
  NTooltip,
  NInputGroup,
  NBackTop,
  NText,
  NSelect,
  NPopover,
  NLayoutHeader,
  NLayoutContent,
  NLayoutSider,
  NLayoutFooter,
  NMenu,
  NSkeleton,
  NDivider,
  NAnchorLink ,
  NAnchor,
  NRow,
  NGi,
  NCol,
  NGrid,
  NIcon,
  NTimeline,
  NTimelineItem,
  NCheckboxGroup,
  NCheckbox,
  NDynamicInput,
  NAlert,
  NInputNumber,
  NSlider,
  NDataTable,
  NBadge,
  NTable,
  NElement,
  NRadioButton,
  NSwitch,
  NCollapseTransition,
  NCollapse,
  NCollapseItem,
  NProgress,
  NStatistic,
  NAvatar,
  NPageHeader,
  NInputGroupLabel,
  NPopconfirm,
  NDropdown,
  NCarousel,

} from 'naive-ui'

const naive = create({
  components: [
    NConfigProvider,
    NInput,
    NButton,
    NButtonGroup,
    NH1,
    NH2,
    NH3,
    NH4,
    NLoadingBarProvider,
    NSpace,
    NCard,
    NTabPane,
    NTabs,
    NSpin,
    NLayout,
    NFormItem,
    NForm,
    NModal,
    NTooltip,
    NInputGroup,
    NBackTop,
    NText,
    NSelect,
    NPopover,
    NLayoutHeader,
    NLayoutContent,
    NLayoutSider,
    NLayoutFooter,
    NMenu,
    NSkeleton,
    NDivider,
    NAnchorLink ,
    NAnchor,
    NRow,
    NGi,
    NCol,
    NGrid,
    NIcon,
    NTimeline,
    NTimelineItem,
    NCheckboxGroup,
    NCheckbox,
    NDynamicInput,
    NAlert,
    NInputNumber,
    NSlider,
    NDataTable,
    NBadge,
    NTable,
    NElement,
    NRadioButton,
    NSwitch,
    NCollapseTransition,
    NCollapse,
    NCollapseItem,
    NProgress,
    NStatistic,
    NAvatar,
    NPageHeader,
    NInputGroupLabel,
    NPopconfirm,
    NDropdown,
    NCarousel,
  ]
})

// router
import createDemoRouter from './routes/router'
import { routes } from './routes/routes'

// sal
// https://jiyoon-park.tistory.com/entry/vue%EC%97%90%EC%84%9C-scroll-animation-library-saljs-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0
import sal from "sal.js"
import "/node_modules/sal.js/dist/sal.css"

const app = createApp(App)
const router = createDemoRouter(app, routes)

// window (used to handle url param links, specified in hash)
// https://stackoverflow.com/questions/54166847/how-to-access-the-window-object-in-vue-js
// https://stackoverflow.com/a/860695
// the rest is coded in /doc.vue
app.config.globalProperties.window = window

app.use(naive)
app.use(router)
app.use(store)
app.use(sal)

app.mount('#app')
