import { darkTheme } from 'naive-ui'

const state = {
  darkTheme: darkTheme,
  lightTheme: null,
  themeName: localStorage.getItem('themeName') || 'light',
  theme: localStorage.getItem('themeName') == 'dark' ? darkTheme : null,
  removeColors: false,

  themeOverrides: {
    common: {
      // "baseColor": "#fdb913",
      "primaryColor": "#fdb913",
      "primaryColorHover": "#fdb913",
      "primaryColorPressed": "#fdb913",
    //   "primaryColorSuppl": "rgb(42, 148, 125)",
      "infoColor": "#c1272d",
      "infoColorHover": "#c1272d",
      "infoColorPressed": "#c1272d",
    //   "infoColorSuppl": "rgb(56, 137, 197)",
      "successColor": "#006a44",
      "successColorHover": "#006a44",
      "successColorPressed": "#006a44",
    //   "successColorSuppl": "rgb(42, 148, 125)",
      "warningColor": "#c1272d",
      "warningColorHover": "#c1272d",
      "warningColorPressed": "#e6c260",
    //   "warningColorSuppl": "rgb(240, 138, 0)",
    //   "errorColor": "#5acea7",
    //   "errorColorHover": "#e98b8b",
    //   "errorColorPressed": "#e57272",
    //   "errorColorSuppl": "rgb(208, 58, 82)",
    //   "textColorBase": "#f4a65s",
    //   "textColor1": "rgba(255, 255, 255, 0.9)",
    //   "textColor2": "rgba(255, 255, 255, 0.82)",
    //   "textColor3": "rgba(255, 255, 255, 0.52)",
    // },
    // Button: {
    //   textColor: '#5acea7'
    // },
    // Select: {
    //   peers: {
    //     InternalSelection: {
    //       textColor: '#FF0000'
    //     }
    //   }
  }
  }
}

const mutations = {
  changeTheme (state) {
    if (state.theme == state.darkTheme) {
      state.theme = state.lightTheme
      state.themeName = 'light'
      localStorage.setItem('themeName', 'light')
    } else {
      state.theme = state.darkTheme
      state.themeName = 'dark'
      localStorage.setItem('themeName', 'dark')
    }
  },
  removeColors(state) {
    state.removeColors = !state.removeColors
  },
}

const getters = {
  getTheme(state) {
    return state.theme
  },
  getThemeOverrides(state) {
    let themeOverrides = state.themeOverrides
    if (state.themeName == 'light') {
      themeOverrides.common.primaryColor = '#006a44'
      themeOverrides.common.primaryColorHover = '#006a44'
      themeOverrides.common.primaryColorPressed = '#006a44'
      themeOverrides.common.successColor = '#fdb913'
      themeOverrides.common.successColorHover = '#fdb913'
      themeOverrides.common.successColorPressed = '#fdb913'
    } else {
      themeOverrides.common.primaryColor = '#006a44'
      themeOverrides.common.primaryColorHover = '#006a44'
      themeOverrides.common.primaryColorPressed = '#006a44'
      themeOverrides.common.successColor = '#fdb913'
      themeOverrides.common.successColorHover = '#fdb913'
      themeOverrides.common.successColorPressed = '#fdb913'
    }
    return themeOverrides
  },
  removeColors(state) {
    return state.removeColors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
