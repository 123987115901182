
const state = () => ({
  hidden: JSON.parse(localStorage.getItem('hiddenCards')) || {},
});

const getters = {
  getState(state) {
    return state.hidden
  },
  isCardHidden: (state) => (payload) => {
    if (state.hidden[payload.lessonId]) {
      return state.hidden[payload.lessonId].includes(payload.cardId)
    } else {
      return false
    }
  }
}

const actions = {
  async changeCardState({ commit }, payload) {
    commit("changeCardState", payload)
  },
};

const mutations = {
  changeCardState(state, payload) {
    state.hidden[payload.lessonId] = state.hidden[payload.lessonId] || []
    if (state.hidden[payload.lessonId].includes(payload.cardId)) {
      state.hidden[payload.lessonId] = state.hidden[payload.lessonId].filter(item => item !== payload.cardId)
    } else {
      state.hidden[payload.lessonId].push(payload.cardId)
    }
    localStorage.setItem('hiddenCards', JSON.stringify(state.hidden))
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
