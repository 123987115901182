
export const routes = [
  {
    path: '/',
    component: () => import('@/pages/landingPage/landingPage.vue')
  },
  {
    path: '/auth',
    component: () => import('@/pages/auth/Auth.vue')
  },
  {
    path: '/verify-email',
    component: () => import('@/pages/auth/VerifyEmail.vue')
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/auth/ResetPassword.vue')
  },
  {
    path: '/docs',
    meta: { requiresAuth: true },
    component: () => import('@/pages/docs/root.vue'),
  },
  {
    path: '/admin_',
    meta: { requiresAuth: true },
    component: () => import('@/pages/admin/root.vue'),
    children: [
      {
        path: '/editorMenu',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/editorMenu.vue')
      },
      {
        path: '/editorContent',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/editorContent.vue')
      },
      {
        path: '/statsServer',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/statsServer.vue')
      },
      {
        path: '/statsMain',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/statsMain.vue')
      },
      {
        path: '/statsLessons',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/statsLessons.vue')
      },
      {
        path: '/users',
        meta: { requiresAuth: true },
        component: () => import('@/pages/admin/users.vue')
      },
    ]
  },
]
