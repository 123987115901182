import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

const state = () => ({
  menu: localStorage.getItem('menu') || null,
  menuForEdditing: null,
  currentMenuSelection: localStorage.getItem('currentMenuSelection') || null,
});

const getters = {
  // full menu object used in menu
  getMenu(state) {
    return state.menu ? state.menu[0]['menuStructure'] : []
  },
  // full menu object used in admin edditing
  getMenuForEdditing(state) {
    return state.menuForEdditing ? state.menuForEdditing[0]['menuStructure'] : []
  },
  getCurrentMenuSelection(state) {
    return state.currentMenuSelection
  },

  // ok this is computationally expensive
  // and should be somehow refactored
  getCurrentMenuData(state) {
    // console.log('doing full loop')
    if (!state.menu) {
      return { data: {}, tema: null, poteme: null }
    }
    const menu = state.menu[0]['menuStructure']
    // console.log('whole menu', menu)
    for (let i = 0; i < menu.length; i++) {
      // console.log('single item', menu[i])
      if (!Object.prototype.hasOwnProperty.call(menu[i], 'children')) {
        // console.log('does not have children, now looking if key match')
        if (state.currentMenuSelection === menu[i].key) {
          // console.log('key match', state.currentMenuSelection, menu[i].key)
          return {
            data: menu[i],
            tema: menu[i].label,
            poteme: null,
          }
        }
      } else {
        for (let j = 0; j < menu[i].children.length; j++) {
          if (state.currentMenuSelection === menu[i].children[j].key) {
            return {
              data: menu[i].children[j],
              tema: menu[i].label,
              poteme: menu[i].children[j].label,
            }
          }
        }
      }
    }
  }
}

const actions = {
  async getMenu({ commit }) {
    const response = await jwtInterceptor
    .get(config.backend_server + "menus/getmenu", {}
    ).catch((error) => {
      console.log(error);
      return error.response
    });
    if (response && response.data && response.status === 200) {
      console.log('menuStore: 200 from getMenu')
      commit("saveMenu", response.data)
    } else {
      console.log('menuStore: Not 200 from get menu')
    }
    return response
  },
  async putMenu({ commit }, payload) {
    let payload_ = {
      "menuStructure": payload.menu,
    }
    console.log(payload_)
    const response = await jwtInterceptor
    .put(config.backend_server + "menus/" + payload.id, payload_
    ).catch((error) => {
      console.log(error);
      return error.response
    });
    if (response && response.data && response.status === 200) {
      console.log('menuStore: 200 from putMenuToDB')
      commit("saveMenu", response.data)
    } else {
      console.log('menuStore: Not 200 from putMenuToDB')
    }
    return response
  },
};

const mutations = {
  saveMenu(state, value) {
    state.menuForEdditing = value

    // validate possible errors
    // really bad and must be refactored to recursion

    // save menu where empty children are deleted
    // else leftSideMenu will not work properly
    let menu = JSON.parse(JSON.stringify(value)) // deep copy
    if (menu && 'menuStructure' in menu[0]) {
      // console.log('WE HAVE MENU', menu)
      menu[0].menuStructure.forEach((item, i) => {
        // console.log(item)
        if (item.children) {
          // console.log('has children')
          if (item.children.length == 0) {
            // console.log('DELETING', menu[0].menuStructure[i].children)
            delete menu[0].menuStructure[i].children
          } else {
            item.children.forEach((child, j) => {
              // console.log('HERE', item.children)
              if (child.children) {
                if (child.children.length == 0) {
                  // console.log('children has children, length is 0')
                  // console.log('DELETING', menu.menuStructure[i].children[j].children)
                  delete menu[0].menuStructure[i].children[j].children
                }
              }
            })
          }
        }
      })
    }
    // console.log("edditing menu", state.menuForEdditing)
    state.menu = menu

    // set current selection to first item
    const firstMenuItemKey = menu[0]['menuStructure'][0].key
    const lastSelectedMenuItemKey = state.currentMenuSelection
    // edge case: what if the menuLastSelected has been deleted by admin?
    state.currentMenuSelection = lastSelectedMenuItemKey ? lastSelectedMenuItemKey : firstMenuItemKey
    // state.currentMenuSelection = firstMenuItemKey
  },
  // this is set in navArrows
  setCurrentMenuSelection(state, value) {
    localStorage.setItem('currentMenuSelection', value)
    state.currentMenuSelection = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
