import { createStore } from "vuex";
import authModule from './modules/auth';
import themeModule from './modules/theme';
import menuModule from './modules/menu';
import lessonsModule from './modules/lessons';
import userDataModule from './modules/userData';
import cardExpandModule from './modules/cardExpand';

const store = createStore({
  modules:{
    auth: authModule,
    theme: themeModule,
    menu: menuModule,
    lessons: lessonsModule,
    userData: userDataModule,
    cardExpand: cardExpandModule,
  }
});

export default store;
