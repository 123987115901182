import jwtInterceptor from '../../shared/jwt.interceptor'
import config from '../../shared/config'

const state = () => ({
  userData: { lessonData: {} } // JSON.parse(localStorage.getItem('userData')) || { lessonData: {} },
});

const getters = {
  getUserData(state) {
    return state.userData;
  },
  isLessonFinished: (state) => (lessonId) => {
    if (Object.keys(state.userData).length === 0) {
      return false
    }
    if (lessonId in state.userData.lessonData) {
      return state.userData.lessonData[lessonId].isFinished
    }
    return false
  }
}

const actions = {
  async updateUserLessonData({ commit }, payload) {
    const response = await jwtInterceptor
    .post(config.backend_server + "userData/" + payload.userId + "/" + payload.lessonId, payload.answers
    ).catch((error) => {
      console.log(error)
      return error.response
    })
    if (response && response.data && response.status === 200) {
      console.log('userDataStore: 200 from updateUserLessonData')
      commit("saveUserDataSelectedLesson", response.data)
    } else {
      console.log('userDataStore: Not 200 from updateUserLessonData')
    }
    return response
  },
  async getUserData({ commit }, payload) {
    const response = await jwtInterceptor
    .get(config.backend_server + "userData/" + payload.userId
    ).catch((error) => {
      console.log(error)
      return error.response
    })
    if (response && response.data && response.status === 200) {
      console.log('userDataStore: 200 from getUserData')
      commit("saveUserData", response.data)
    } else {
      console.log('userDataStore: Not 200 from getUserData')
    }
    return response
  },
  async deleteUserLessonData({ commit }, payload) {
    // console.log('removing', payload)
    const response = await jwtInterceptor
    .delete(config.backend_server + "userData/" + payload.userId + "/" + payload.lessonId
    ).catch((error) => {
      console.log(error)
      return error.response
    })
    if (response && response.status === 200) {
      console.log('userDataStore: 200 from deleteUserLessonData')
      commit("deleteUserDataSelectedLesson", payload.lessonId)
    } else {
      console.log('userDataStore: Not 200 from deleteUserLessonData')
    }
    return response
  },
};

const mutations = {
  saveUserData(state, value) {
    state.userData = value
    // localStorage.setItem('userData', JSON.stringify(value))
  },
  saveUserDataSelectedLesson(state, value) {
    // console.log(state.userData.lessonData)
    // console.log(value)
    const lessonId = Object.keys(value)[0]
    state.userData.lessonData[lessonId] = value[lessonId]
  },
  updateUserDataLessonLastVisit(state, lessonId) {
    const currentTime = new Date().toISOString()
    if (!(lessonId in state.userData.lessonData)) {
      state.userData.lessonData[lessonId] = {}
    }
    state.userData.lessonData[lessonId].lastVisit = currentTime
    // localStorage.setItem('userData', JSON.stringify(state.userData))
  },
  deleteUserDataSelectedLesson(state, lessonId) {
    // here also in the back end, we leave only lastVisit (isFinished and answers are removed)
    state.userData.lessonData[lessonId] = { lastVisit: state.userData.lessonData[lessonId].lastVisit }
    // console.log('delete', lessonId, state.userData)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
